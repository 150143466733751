import React, { useState } from 'react';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import axiosInstance from '../Api/axios';
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setResponseMessage('');

        try {
            const response = await axiosInstance.post('/contact', formData); // Ensure the endpoint is correct
            setResponseMessage(response.data.message);
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } catch (err) {
            setError(err.response?.data?.error || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="overflow-hidden w-full">
            {/* About Us Section */}
            <div className="relative bg-cover bg-center w-full h-[300px] lg:h-[300px]" style={{ backgroundImage: "url('/assets/img/ll.jpg')" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-sky-500 to-blue-500 opacity-35"></div>
                <div className="relative z-10 flex flex-col justify-center items-center text-center text-white py-16 w-full h-full">
                    <h1 className="text-4xl lg:text-5xl font-bold mb-4">Contact Us</h1>
                    <p className="text-sm lg:text-lg mb-8">Contact Us with Any Queries.</p>
                </div>
            </div>

            <div className="contactinner-section-area py-10 lg:py-20 bg-gray-100 dark:bg-slate-900 dark:text-white">
                <div className="container mx-auto px-4 py-12">
                    <div className="flex flex-col items-center text-center mb-8">
                        <div className="w-full lg:w-2/3">
                            <div className="contact5-textarea5">
                                <span className="font-outfit text-lg font-medium text-sky-600 dark:text-sky-300 inline-block mb-6">
                                    Contact Us
                                </span>
                               
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center gap-6">
                      
                        <Card className="p-6 w-80 bg-gray-200 dark:bg-slate-800 rounded-lg flex items-center justify-center">
                            <div className="flex flex-col items-center">
                                <img src="assets/img/mail1.svg" alt="Email Icon" className="w-16 h-16 mb-4" />
                                <p className="text-base mb-2">Mail Us</p>
                                <a href="mailto:info@streakify.in" className="text-xl font-bold">info@streakify.in</a>
                            </div>
                        </Card>
                      
                    </div>
                </div>



            </div>

           


            <div className="contactinner-section-area py-10 lg:py-20 bg-gray-100 dark:bg-slate-900 dark:text-white min-h-screen flex items-center justify-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-lg lg:text-3xl font-bold mb-10 text-center">
                        Fill out the form and our team will get back to you as soon as we can!
                    </h2>
                    <div className="flex flex-col lg:flex-row lg:gap-12 items-stretch justify-center max-w-6xl mx-auto">

                        


                        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-center">
                            <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-4 lg:p-6 flex flex-col justify-center h-full" style={{ maxWidth: '600px' }}>
                                <span className="text-2xl font-bold mb-4 block text-center">Connect With Streakify Team.</span>
                                <p className="text-sm lg:text-base mb-4 text-center">
                                    Fill out the form and our team will get back to you as soon as we can!
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium mb-1">Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium mb-1">Your Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="subject" className="block text-sm font-medium mb-1">Subject</label>
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="message" className="block text-sm font-medium mb-1">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows="4"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded-lg dark:border-slate-700 dark:bg-slate-900 dark:text-white"
                                            required
                                        ></textarea>
                                    </div>
                                    <button
                                        type="submit"
                                        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Message'}
                                    </button>
                                </form>
                                {responseMessage && <p className="text-green-600 mt-4">{responseMessage}</p>}
                                {error && <p className="text-red-600 mt-4">{error}</p>}
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>

    );
};

export default Contact;