import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import 'primeicons/primeicons.css';
import './HomePage.css'; // You'll need to create this CSS file
import Footer from '../components/Footer';

const Home = () => {
  const features = [
    { icon: 'pi pi-users', title: 'Connect', description: 'Build a powerful network with students and alumni' },
    { icon: 'pi pi-briefcase', title: 'Opportunities', description: 'Discover exciting career openings tailored for you' },
    { icon: 'pi pi-book', title: 'Resources', description: 'Access curated educational materials to boost your skills' },
    { icon: 'pi pi-compass', title: 'Guidance', description: 'Receive personalized career advice from industry experts' },
  ];

  return (
    <div className="home-page">
      <header className="hero">
        <nav>
          {/* <img src="/logo.png" alt="CollegeMate Logo" className="logo" /> */}
         
        </nav>
        <div className="hero-content">
          <h1>Unlock Your Potential with CollegeMate</h1>
          <p>Empowering students and alumni network for colleges in India to achieve their dreams</p>
          <Button label="Join Now" className="p-button-raised p-button-rounded p-button-lg mt-6" />
        </div>
      </header>

      <section className="about mt-10">
        <div className="container">
          <h2>Bridging the Gap in Higher Education</h2>
          <p>
            CollegeMate is a revolutionary platform by Streakify Technologies, 
            designed to level the playing field for tier 2 college students and alumni in India. 
            We provide cutting-edge tools for networking, skill development, and career advancement.
          </p>
        </div>
      </section>

      <Divider />

      <section className="features">
        <div className="container">
          <h2>Empowering Features</h2>
          <div className="feature-grid">
            {features.map((feature, index) => (
              <Card key={index} className="feature-card">
                <i className={`${feature.icon} feature-icon`}></i>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Transform Your Career?</h2>
          <p>Join thousands of students and alumni who are already benefiting from CollegeMate</p>
          <Button label="Get Started" icon="pi pi-arrow-right" className="p-button-raised p-button-rounded p-button-lg" />
        </div>
      </section>

 
    </div>
  );
};

export default Home;