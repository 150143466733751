import React from 'react';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import { Button } from 'primereact/button';
import './Mission.css'; // You'll need to create this CSS file

const Mission = () => {
  const missionStatements = [
    {
      icon: 'pi pi-star',
      title: 'Empowerment',
      content: 'Empower students and alumni from colleges to reach their full potential.'
    },
    {
      icon: 'pi pi-link',
      title: 'Connection',
      content: 'Bridge the gap between academia and industry through meaningful connections.'
    },
    {
      icon: 'pi pi-chart-line',
      title: 'Growth',
      content: 'Foster personal and professional growth through tailored resources and opportunities.'
    },
    {
      icon: 'pi pi-globe',
      title: 'Inclusivity',
      content: 'Create an inclusive ecosystem that values diversity and promotes equal opportunities.'
    }
  ];

  const roadmap = [
    { status: 'Idea', date: '2016', icon: 'pi pi-send', color: '#9C27B0', content: 'CollegeMate platform Idea' },
    { status: 'In Progress', date: '2024', icon: 'pi pi-cog', color: '#673AB7', content: 'Building Application' },
    { status: 'Upcoming', date: '2025', icon: 'pi pi-mobile', color: '#FF9800', content: 'Launch mobile app for enhanced accessibility' },
    { status: 'Future', date: '2026', icon: 'pi pi-globe', color: '#607D8B', content: 'Reach to every college in india' }
    
  ];

  return (
    <div className="mission-page">
      <header className="mission-header">
        <h1>Our Mission</h1>
        <p>Transforming careers and bridging educational gaps across India</p>
      </header>

      <section className="mission-statements">
        <div className="container">
          <h2>What Drives Us</h2>
          <div className="statement-grid">
            {missionStatements.map((statement, index) => (
              <Card key={index} className="statement-card">
                <i className={`${statement.icon} statement-icon`}></i>
                <h3>{statement.title}</h3>
                <p>{statement.content}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="vision">
        <div className="container">
          <h2>Our Vision</h2>
          <p>
            We envision a future where every student, regardless of their college tier, 
            has equal access to opportunities and resources to build a successful career. 
            CollegeMate aims to be the catalyst that transforms the educational landscape 
            in India, creating a level playing field for all aspiring professionals.
          </p>
        </div>
      </section>

      <section className="roadmap">
        <div className="container">
          <h2>Our Journey</h2>
          <Timeline value={roadmap} align="alternate" className="customized-timeline" marker={item => (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.color }}>
              <i className={item.icon}></i>
            </span>
          )} content={item => (
            <Card title={item.status} subTitle={item.date}>
              <p>{item.content}</p>
            </Card>
          )} />
        </div>
      </section>

      <section className="join-mission">
        <div className="container">
          <h2>Be Part of Our Mission</h2>
          <p>Join us </p>
          <Button label="Join CollegeMate" icon="pi pi-users" className="p-button-raised p-button-rounded" />
        </div>
      </section>
    </div>
  );
};

export default Mission;