import { useState } from 'react';

const Notification = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-blue-600 text-white p-4 text-center">
      <p>
      This product is developed by Streakify Technologies. The application is currently in development. For assistance or inquiries, please contact us at <a href="mailto:info@streakify.in" className="underline">info@streakify.in</a>.
      </p>
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-0 right-0 mt-2 mr-2 text-white hover:text-gray-300"
      >
        ✕
      </button>
    </div>
  );
};

export default Notification;
