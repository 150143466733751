import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';
import { Menubar } from 'primereact/menubar';
import ThemeSwitcher from './themeSwitcher';

const Header = () => {
    const [visible, setVisible] = useState(false);

    const items = [
        {
            label: 'Home',
            command: () => window.location.href = '/',
        },
        {
            label: 'About Us',
            command: () => window.location.href = '/about',
        },
        {
            label: 'Our Mission',
            command: () => window.location.href = '/mission',

        },
        {
            label: 'Contact Us',
            command: () => window.location.href = '/contact',
        },
    ];

    const start = <span className="text-2xl font-bold text-blue-700 dark:text-white">Collegemate</span>;

    const end = (
        <div className="flex items-center gap-2">
            <ThemeSwitcher />
        </div>
    );

    return (
        <header className="sticky top-0 z-50 w-full bg-white dark:bg-gray-900 text-slate-900 dark:text-white">
            <div className="flex items-center justify-between px-5 py-4">
                {/* Left side */}
                <div className="flex items-center">
                    {start}
                </div>

                {/* Menu Items for large screens */}
                <div className="hidden lg:flex flex-grow justify-center">
                    <Menubar model={items} className="border-none bg-transparent text-slate-900 dark:text-white" />
                </div>

                {/* Right side */}
                <div className="flex items-center gap-2">
                    <div className="lg:hidden">
                        <Button icon="pi pi-bars" onClick={() => setVisible(true)} className="text-slate-400 bg-transparent border-0 hover:bg-transparent focus:bg-transparent bg-white dark:bg-gray-900 text-slate-900 dark:text-white" style={{ color: 'gray' }} />
                    </div>
                    {end}
                </div>
            </div>

            {/* Sidebar for small and medium screens */}
            <Sidebar visible={visible} onHide={() => setVisible(false)} baseZIndex={1000}>
                <PanelMenu model={items} className="w-full md:w-80 lg:w-96" />
                <div className="p-4">
                    <h3 className="text-lg font-semibold">Contact Us</h3>
                    <p className="mt-2 text-sky-700">Address: Banagalore,India-560034
                        <br /> Hyderabad,India-500072

                    </p>
                    <p className='text-sky-700'>Email: <a href="info@streakify.in" className="text-blue-600 hover:underline">info@streakify.in</a></p>
                    <p className='text-sky-700'>Phone: +91-8105827558</p>
                    <div className="mt-4 flex space-x-3 ">
                        <a href="https://x.com/streakifyin" className="text-blue-500 hover:text-blue-700" aria-label="Twitter">
                            <i className="pi pi-twitter text-xl text-sky-700"></i>
                        </a>
                        <a href="https://linkedin.com/streakify" className="text-blue-700 hover:text-blue-900" aria-label="LinkedIn">
                            <i className="pi pi-linkedin text-xl text-sky-700"></i>
                        </a>
                        <a href="https://facebook.com/streakify.in" className="text-blue-600 hover:text-blue-800" aria-label="Facebook">
                            <i className="pi pi-facebook text-xl text-sky-700"></i>
                        </a>
                        {/* Add more social links as needed */}
                    </div>
                </div>
            </Sidebar>


        </header>
    );
};

export default Header;

