import React from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import 'primeicons/primeicons.css';

export default function Footer() {
  return (
    <footer>
    <div className="container">
      <div className="footer-content">
        <div className="footer-section">
          <h4>CollegeMate</h4>
          <p>Empowering careers, one connection at a time.</p>
        </div>
       
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
           
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Connect With Us</h4>
          <div className="social-icons">
            <i className="pi pi-facebook"></i>
            <i className="pi pi-twitter"></i>
            <i className="pi pi-linkedin"></i>
            <i className="pi pi-instagram"></i>
          </div>
        </div>
      </div>
      <Divider />
      <p className="copyright">&copy; 2024 Streakify Technologies. All rights reserved.</p>
    </div>
  </footer>
  )
}
