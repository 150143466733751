import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import 'primeicons/primeicons.css';
import './AboutPage.css'; // Create this CSS file for styling

const About = () => {
  return (
    <div className="about-page">
      <header className="about-hero">
        <div className="hero-content">
          <h1>About CollegeMate</h1>
          <p>Empowering students and alumni to achieve their full potential.</p>
          <Button label="Learn More" className="p-button-raised p-button-rounded p-button-lg mt-4" />
        </div>
      </header>

      <section className="about-info mt-6">
        <div className="container">
          <h2>Our Mission</h2>
          <p>
            At CollegeMate, we are dedicated to bridging the gap between students, alumni, and career opportunities. Our platform connects students with a vast network of professionals, provides access to valuable resources, and offers tailored career guidance.
          </p>
          <p>
            We believe in creating a supportive community that fosters growth and success for all members. Our mission is to empower students and alumni through innovative tools and a strong network.
          </p>
        </div>
      </section>

      <Divider />

      <section className="our-team">
        <div className="container">
          <h2>Meet Our Team</h2>
          <div className="team-grid">
            {/* Example team members */}
            <Card className="team-card">
         
              <h3>Shiva JSP</h3>
              <p>Founder</p>
            </Card>
            
            {/* Add more team members as needed */}
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Join Us in Making a Difference</h2>
          <p>Become a part of our community and help shape the future of education and career development.</p>
          <Button label="Contact Us" icon="pi pi-envelope" className="p-button-raised p-button-rounded p-button-lg" />
        </div>
      </section>
    </div>
  );
};

export default About;
